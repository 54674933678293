
import { defineComponent, PropType } from 'vue'

export default defineComponent ({
  name: 'VLightPreloader',
  props: {
    fixed: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: true,
    },
    transparent: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    background: {
      type: String as PropType<string>,
      required: false,
      default: 'transparent',
    },
    text: {
      type: String as PropType<string>,
      default: null,
    },
  },
})
